.whyItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f0f0f0;
  /* background: url('../../assets/images/main.jpeg') left 50% top 50% / cover no-repeat fixed !important; */
}

.mobileWhyItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f0f0f0;
  /* background: url('../../assets/images/main.jpeg') left 50% top 50% / cover no-repeat fixed !important; */
}

.centerWhy{ 
  text-align: center;
}

/* .layout {
  width: 100%;
  height:100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6)!important;
} */

.whyLeftBlock {
  display: flex;
  width: 40%;
  height: 100%;
  /* background-color: #192628; */
  justify-content: center;
  padding: 30px 0px;
  align-items: center;
  box-sizing: border-box;
}

.mobileWhyLeftBlock {
  display: flex;
  width: 40%;
  height: 100%;
  /* background-color: #192628; */
  justify-content: center;
  padding: 30px 0px;
  align-items: center;
  box-sizing: border-box;
}


.whyRightBlock {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  padding: 110px 0px 160px 0px;
  box-sizing: border-box;
}

.mobileWhyRightBlock {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;
  box-sizing: border-box;
}

 

.whyItem {
 max-width: 960px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-size: 24px;
  color: #000; 
  font-size: 19px;
  line-height: 1.55;  
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;  
}

.mobileWhyItem {
 max-width: 960px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  font-size: 24px;
  color: #000; 
  font-size: 19px;
  line-height: 1.55;  
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;  
}
 

.titlePlus {
  font-size: 30px;
  color: white;
  font-weight: 600 ;
  font-family: 'Roboto', sans-serif ;
  /* z-index: 9; */
}
 
.whyImgContainer {
  display: flex; 
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
  
.whyInfoContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  
.mobileWhyInfoContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.whyTitle {
  display: flex;
  width: 100%;
  height: 20%;
  margin-top: 12px;
    margin-bottom: 25px; 
  align-items: center;
  justify-content: center;  
  font-weight: 600; 
}

.mobileWhyTitle {
  display: flex;
  width: 100%;
  height: 20%;
  margin-top: 12px;
    margin-bottom: 25px; 
  align-items: center;
  justify-content: center;  
  font-weight: 600; 
}

.whyDescription {
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center; 
  font-family: 'IBM Plex Serif', serif;
    font-weight: 300;
    color: #000000;
    font-size: 24px;
    max-width: 560px;
    text-align: center;
    line-height: 1.50;
    margin-top: 30px;
}

.mobileWhyDescription {
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center; 
  font-family: 'IBM Plex Serif', serif;
    font-weight: 300;
    color: #000000;
    font-size: 24px;
    max-width: 560px;
    text-align: center;
    line-height: 1.50;
    margin-top: 30px;
}

.whyDescriptionCart {
  display: flex;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;
  max-width: 360px;
  font-size: 15px;
  line-height: 1.50;
  color: #000000;
  text-align: center;
  align-items: start;
  height: 140px;
}

.mobileWhyDescriptionCart {
  display: flex;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;
  max-width: 360px;
  font-size: 15px;
  line-height: 1.50;
  color: #000000;
  text-align: center;
  align-items: start;
  height: 140px;
}


.whyPhotoCheck {
  display: flex;
  width: 80px; 
  height: auto;  
}

.mobileWhyPhotoCheck {
  display: flex;
  width: 80px; 
  height: auto;  
}




@media (width <= 1150px ) { 
  .plusItem {
    font-size: 20px;
  } 
}

@media (width <= 990px ) { 
  .plusRightBlock {
    width: 80%;
  }
}

