.plusItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f0f0f0;
  /* background: url('../../assets/images/main.jpeg') left 50% top 50% / cover no-repeat fixed !important; */
}

/* .layout {
  width: 100%;
  height:100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6)!important;
} */

.plusLeftBlock {
  display: flex;
  width: 40%;
  height: 100%;
  /* background-color: #192628; */
  justify-content: center;
  padding: 30px 0px;
  align-items: center;
  box-sizing: border-box;
}


.plusRightBlock {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  padding: 110px 0px 160px 0px;
  box-sizing: border-box;
}


.mobilePlusRightBlock {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  /* background-color: rgb(236, 226, 213); */
  justify-content: space-evenly;
  padding: 30px 0px;
  box-sizing: border-box;
}

.plusItem {
 max-width: 960px;  
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: 150px;
  font-size: 24px;
  color: #000; 
  font-size: 19px;
  line-height: 1.55;  
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;  
}

.mobilePlusItem {
 max-width: 960px;  
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: auto;
  font-size: 24px;
  color: #000; 
  font-size: 19px;
  line-height: 1.3;  
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;  
  margin-bottom: 70px;
}
 

.titlePlus {
  font-size: 30px;
  color: white;
  font-weight: 600 ;
  font-family: 'Roboto', sans-serif ;
  /* z-index: 9; */
}
 
.plusImgContainer {
  display: flex;
  width: 20%;
  height: 100%;
  align-items: start;
  justify-content: end;
  padding-right: 40px;
}
 
.mobilePlusImgContainer {
  display: flex;
    width: 20%;
    height: 100%;
    align-items: start;
    justify-content: center;
    padding-right: 10px;
    box-sizing: border-box;
}
  
.plusInfoContainer {
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
  
.mobilePlusInfoContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobilePlusTitle {
  display: flex;
  width: 100%;
  height: auto  ;
  padding-bottom: 10px;
  /* align-items: center;
  justify-content: center;   */
  font-weight: 600;
  line-height: 1.2;
}

.plusTitle {
  display: flex;
  width: 100%;
  height: 20%;
  padding-bottom: 10px;
  /* align-items: center;
  justify-content: center;   */
  font-weight: 600; 
}

.plusDescription {
  display: flex;
  width: 100%;
  height: 80%;
  /* align-items: center;
  justify-content: center;  */
}

.mobilePlusDescription {
  display: flex;
  width: 100%;
  height: 80%;
  font-size: 15px;
  line-height: 1.45;
  /* align-items: center;
  justify-content: center;  */
}







@media (width <= 1150px ) { 
  .plusItem {
    font-size: 20px;
  } 
}

@media (width <= 990px ) { 
  .plusRightBlock {
    width: 80%;
  }
}

