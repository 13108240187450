.footer_container {
  display:flex;flex-direction:column;width:100%;background-color:#242424;align-items:center;justify-content:center;padding-top: 100px;padding-bottom: 100px;
}
.footer_up {
  display:flex;flex-direction:row;width:100%;background-color:#242424;align-items:flex-start;justify-content:space-evenly;padding-top:50px;z-index:0
}
  .footer_down {
    display:flex;flex-direction:column;width:100%;background-color:#242424;align-items:center;justify-content:center;z-index:0
  }
 
  .coperait_txt{
    user-select:none; padding:20px 0;
    color: #ffffff;
    letter-spacing: 1px;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.35;
    margin-bottom: 45px;
  }
 
  .footer_txt{
    cursor:pointer;user-select:none;text-decoration: none;margin:0 36px 18px 0;font-family:'Montserrat-Bold';font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:.79px;color:#9f9f9f;color:#999
  }
  .footer_txt:hover{
    color:#fff
  }
  /* .footer_down::before{
    content:"";display:block;width:80%;height:0px;background:#212729;margin:50px auto
  } */
  .footer_desription_section_right{
    padding-top: 40px;
    align-self:flex-start
  }
  .footer_desription_section_right a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left{
    padding-top: 40px;
  }
  .visa_container{
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    height: auto;
  }
  .footer_social_section{
    margin-left:0px
  }
  .footer_logo_section{
    padding-right:0px
  } 

  .iframe {
    width: 460px;
    height: 230px;
  }

  .mobile-iframe {
    width: 300px;
    height: 220px;
    margin-bottom: 10px;
  }
  .mobile-footer_txt{
    cursor:pointer;user-select:none;text-decoration: none;margin:0 5px 10px 0;font-family:'Montserrat-Bold';font-size:11px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:.79px;color:#9f9f9f;color:#999
  }
  .mobile-coperait_txt{ 
    user-select:none; padding:20px 0;
    color: #ffffff;
    letter-spacing: 1px;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.35; 
  }

  .telephone_footer {
    clear: both;
    font-family: 'IBM Plex Serif';
    color: #ffffff; 
    display: inline-block; 
    font-weight: 300; 
    font-size: 36px;
    line-height: 1.23;  
    margin-bottom: 45px;
  }

  .mobile-telephone_footer {
    clear: both;
    font-family: 'IBM Plex Serif';
    color: #ffffff; 
    display: inline-block; 
    font-weight: 300; 
    font-size: 32px;
    line-height: 1.23;  
    margin-bottom: 45px;
  }






@media ( width <= 1100px ) { 
  .iframe {
    width: 320px;
    height: 200px;
  }

  .footer_up {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer_desription_section_right{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
  .footer_desription_section_left{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
}
@media ( width >= 707px ) { 
  .footer_container {
    min-width: 745px;
  }
}

