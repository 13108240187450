.aboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 170px 50px;
  background-color: white;
}

.mobileAboutWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  background-color: white;
}

.mobileAboutWrapper .aboutTextWrapper {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 10px;
  background-color: white;
}

.aboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 20px 0px;
}

.mobileAboutTopWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.aboutBottomWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
}

.mobileAboutBottomWrapper {
  display: flex;
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin-top: 20px;
}

.aboutTextWrapper {
  font-size: 17px;
  font-size: 17px;
  display: flex;
  width: 500px;
  height: 100%; 
  font-family: sans-serif;
  flex-direction: column;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
  margin-left: 30px;
  margin-right: 30px;
}

.aboutPhotoWrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileAboutWrapper .aboutPhotoWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.photoCheck {
  width: 55px;
}

.mobilePhotoCheck {
  width: 40px;
}

.photoAboutOne {
  width: 80%; 
  background-position: center;
  background-image:  url('../../assets/images/1.jpeg');
  padding-bottom: 75%;
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    max-width: 100%; 
    float: left; 
    margin-right: 20px;
    width: 100%;
}

.photoAboutTwo {
  width: 80%; 
  background-position: center;
  background-image:  url('../../assets/images/2.jpeg');
  padding-bottom: 75%;
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    max-width: 100%; 
    float: left; 
    margin-right: 20px;
    width: 100%;
}

.photoAboutThree {
  width: 80%; 
  background-position: center;
  background-image:  url('../../assets/images/3.jpeg');
  padding-bottom: 75%;
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    max-width: 100%; 
    float: left; 
    margin-right: 20px;
    width: 100%;
    
}

.aboutDescription {
  display: flex;
  max-width: 560px; 
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 100px;
    font-size: 25px;
    line-height: 1.30; 
    font-family: 'IBM Plex Serif', serif;
    font-weight: 300;
    color: #000000; 
    text-align: center;

}

.mobileAboutDescription {
  display: flex;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.20;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;
  color: #000000;
  text-align: center;
}

.aboutTitleServices {
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 1.23; 
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  color: #000000; 
}

.aboutDescriptionServices {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.3; 
  font-family: 'IBM Plex Serif', serif;
  font-weight: 300;
  color: #000000; 
}
 
.servicesContainer {
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 100px;
}
 
.mobileServicesContainer {
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.mobileServicesContainerLeft {
  display: flex;
  width: 97%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.mobileServicesContainerRight{
  display: flex;
  width:97%;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.servicesLeftHead {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center; 
  font-weight: 600;
  font-family: serif;
  font-size: 30px;
  
}

.mobileServicesLeftHead {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center; 
  font-weight: 600;
  font-family: serif;
  font-size: 30px;
  margin-bottom: 30px;
}

.servicesRightHead {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center; 
  font-weight: 600;
    font-family: serif;
    font-size: 30px;
}

.mobileServicesRightHead {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center; 
  font-weight: 600;
    font-family: serif;
    font-size: 30px;
    margin: 20px 0px;
}
