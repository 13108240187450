.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 1279px;
  /* margin-top: 100px; */
  background-color: #444;
  background-image: url('../../assets/images/main.jpeg');
  /* background-position: 0px 30px; */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.mobileItemWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 812px;
  /* margin-top: 100px; */
  background-color: #444;
  background-image: url('../../assets/images/main.jpeg');
  /* background-position: 0px 30px; */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.rowItemRig {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 33%;
  height: 100%;
}

.wrapperVersion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 10px 0px;
}

.colInfoBlock {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 30px;
}

.txt_maintxt {
  height: auto;
  font-size: 33px;
  font-weight: 600;
  font-family: system-ui;
  color: #444;
  padding: 0px 0px 20px 22px;
  border-bottom: 2px solid #ea402f;
  font-family: 'Roboto', sans-serif;
}

.txt_main_subtxt {
  width: 80%;
  font-size: 14px;
  color: #222;
  padding-top: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  border-top: 2px solid #ea402f;
}

.mobile-txt_main_subtxt {
  width: 80%;
  font-size: 12px;
  color: #222;
  padding-top: 10px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  border-top: 2px solid #ea402f;
}

.wrapper_maintxt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  /* padding: 70px 30px 50px 30px; */
  width: 420px;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
  /* border-radius: 5px; */
  /* margin-top: 190px; */
  /* margin-left: 100px; */
  /* position: relative; */
  /* box-sizing: border-box; */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-wrapper_maintxt { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  /* padding: 70px 30px 50px 30px; */
  width: 420px;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
  /* border-radius: 5px; */
  /* margin-top: 190px; */
  /* margin-left: 100px; */
  /* position: relative; */
  /* box-sizing: border-box; */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-wrapper_maintxt .titleText {
  text-align: center;
}

.wrapper_img_mainlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.img_mainlogo {
  width: 120%;
  height: 120%;
  padding-top: 200px;
}

.colTimerBlock {
  display: flex;
  width: 70%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: absolute;
  /* bottom: 20px; */
}

.wrapperTimer {
  display: flex;
  width: 50%;
  height: 40px;
  justify-content: center;
  align-items: flex-end;
}

.wrapperTimer h1{
  font-size: 30px;
}
.timerTxt {
  color: #222;
  font-size: 23px;
  padding: 8px;
}

.mobile-txtTimer {
  color: #222;
  font-size: 21px;
  padding: 3px;
  font-weight: 600;
  text-align: center
}

.colButtonBlock {
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 170px;
}
.wrapperButton {
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ea402f;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  padding: 0 22px;
  font-size: 14px;
  line-height: 40px;
  color: #FFF;
  font-weight: 700;
  border-radius: 3px;
  margin-top: 30px;
}

.wrapperButton:hover {
  background-color: rgb(72, 72, 240);
}

.txtButton {
  font-size: 15px;
  font-weight: 600;
  font-family: system-ui;
  color: white;
}

.txtTimer {
  font-size: 23px;
  font-weight: 800;
  text-align: center;
  line-height: 1.4;
  font-family: monospace;
  color: #222;

  display: flex; 
    text-align: center; 
    font-family: IBM Plex Serif !important;
    justify-content: center;
    align-items: center; 
}

.mobile-txtTimer {
  font-size: 23px;
  font-weight: 800;
  text-align: center;
  line-height: 1.4;
  font-family: monospace;
  color: #222;

  display: flex; 
    text-align: center; 
    font-family: IBM Plex Serif !important;
    justify-content: center;
    align-items: center; 
}

.spantxt {
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  font-family: 'IBM Plex Serif', serif;
  padding: 24px 0px 38px 0px;
  letter-spacing: 0.5px;
  color: #fff;
  font-size: 72px;
  line-height: 1.17;
}

.mobile-spantxt {
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
  font-family: 'IBM Plex Serif', serif;
  padding: 24px 0px 38px 0px;
  letter-spacing: 0.5px;
  color: #fff;
  font-size: 32px;
  line-height: 1.17;
}
 
@media (600px >= width <= 780px ) { 
  .mobile-wrapper_maintxt {
    width: 40%;
    margin-left: 210px;
    margin-top: 250px;
  }
  .colInfoBlock {
    padding-left: 30px; 
  }
}

@media ( width <= 600px ) { 
  .itemWrapper {
    /* background-position: -680px 140px; */
    background-repeat: no-repeat;
    background-size: cover;
    
  }
 
}

